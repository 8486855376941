@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');




/*text areas / input fields*/

.textarea-small {
  height: 20px;
  width: 100%;
  transition: all 0.3s;
  resize: none;
}

.textarea-large {
  height: 10vh;
  resize: vertical;
  max-height: 1500px;
  margin-bottom: 1vh;
  margin-top: 1vh;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  color: #313131;
  font-weight: 450 !important;
  white-space: pre-wrap;
  font-weight: 900;
  width: 100%;
  transition: all 0.3s;
  border: 2px solid #e5e5e5;
  border-radius: 4px;
}

.textarea-verylarge {
height: 60vh;
resize: vertical;
max-height: 1500px;
margin-bottom: 1vh;
margin-top: 1vh;
font-family: inherit;
font-size: 1rem;
line-height: 1.5;
color: #313131;
font-weight: 450 !important;
white-space: pre-wrap;
font-weight: 900;
width: 100%;
transition: all 0.3s;
border: 2px solid #e5e5e5;
border-radius: 4px;
}

.textarea-large.expanded {
  height: 30vh;
  transition: all 0.3s;
}

/* text area scroll bar*/
.textarea-large::-webkit-scrollbar-track{
	background-color: #e2e2e2;
  
}

.textarea-large::-webkit-scrollbar{
	width: 6px;
	background-color: #F5F5F5;
}

.textarea-large::-webkit-scrollbar-thumb{
	border-radius: 100px;
	background-color: #545454;

}

#jobTitleInput {
  width: 50% !important;
}

/*end of input stuff*/


.landing-page {
padding-left: 20px;
padding-right: 20px;
}

.divider {
  border-top: 1px solid #ccc; /* Sets the color and thickness of the line */
  margin: 20px 0; /* Adjusts the space above and below the line */
  width: 90%; /* Sets the width of the line to be 90% of its parent element */
  margin-left: auto; /* Centers the line horizontally */
  margin-right: auto;
  margin-top: 40px;
}






/*error registration message*/
.error-message {
  color: red;
  background-color: #ffeeee;
  padding: 10px;
  border: 1px solid red;
  border-radius: 5px;
  margin-bottom: 15px;
  opacity: 1;
}

.success-message {
  color: rgb(39, 146, 35);
  background-color: #eeffee;
  padding: 10px;
  margin: 10px;
  border: 1px solid rgb(74, 222, 104);
  border-radius: 5px;
  margin-bottom: 15px;
  width: fit-content;
  opacity: 1;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.fade-out {
  animation-name: fadeOut;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}


/*headings*/
h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 26px;
  font-style: normal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 22px;
  font-style: normal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.resumesections {
  margin-top: 40px;
}

h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  font-style: normal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  margin-top: .5em;
  margin-bottom: .5rem;
}


/* Dashboard CSS */
.input-section,
.generative-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

/*component blocks*/

.ResumeFeedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1%;
  height: 100%;
  width: 100%;
}

.ScreenerQuestions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1%;
  height: 100%;
  width: 100%;
}

.ResumeOptimizer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1%;
  height: 100%;
  width: 100%;
  padding-bottom: 30px;
}


.skeleton {
  padding: 20px;
}

.textstream {
  padding-left: 20px;
  padding-right: 20px;
}



.result-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px !important;
  box-shadow: 0 8px 20px rgba(1, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 2.5rem;
  overflow: none;
  opacity: 1;
}

.result-inner {
  padding-left: 40px;
  padding-right: 40px;

}


/*fade in and out animation for result-container*/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadein {
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.fadeout {
  animation-name: fadeOut;
  animation-duration: 3s;
  animation-fill-mode: backwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.ref {
  width: auto;
}





/*saved result section, centered*/

.saved-result-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  width: 100%;

}

.saved-jobdata-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;

}


.saved-alignment-item h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  cursor: pointer;
}


.title-close {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.saved-close-button {
  cursor: pointer;
  float: right;
  font-size: 35px;
  padding-right: 10px;
  padding-top: 10px;
}

.save-title-expand {
  cursor: pointer;
  float: right;
  font-size: 35px;
  padding-right: 10px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 250x;
}

.expand-collapse-saved-results {
margin-top: 10px;
margin-left: 10px;
}






/*loading animation*/
.loading-gif {
  width: 60px; 
  margin: auto;

}

.chat-loading-gif {
  float:  right;
  margin-top: 15px;
  margin-right: 15px;
}

.loading-container {
  flex-direction: column;
  align-items: center;
  text-align: center;

}

.loading-message {
  font-size: 10x;
  font-family: 'Montserrat';
  width: auto;
  color: black;
  margin-bottom: 5px;
  transition: opacity 0.3s ease 0.3s, transform 0.3s ease-in-out, width 0.3s ease-in-out;
}


/* Styles for LoadingBanner */
.loading-banner {
  position: fixed; /* Fixed to the viewport */
  top: 0;
  left: 50%; /* Center the banner */
  transform: translateX(-50%); /* Ensure it's centered */
  width: 250px; /* Set width to 50% */
  padding: 10px 20px;
  max-width: 500px;
  background-color: #ffffff;
  color: white;
  z-index: 9999;  /* Increase z-index to make sure it's on top */
  display: flex;
  justify-content: center;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); /* Add a little shadow for better visibility */
  opacity: 1; /* Set to visible by default */
  /* Adding transition for opacity */
  transition: opacity 0.3s ease 0.3s, transform 0.3s ease-in-out, width 0.3s ease-in-out;
  opacity: 0; /* By default, the banner is invisible */
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}



/*for message banner*/
.message-banner {
  position: fixed; /* Fixed to the viewport */
  top: 0;
  left: 50%; /* Center the banner */
  transform: translateX(-50%); /* Ensure it's centered */
  width: 250px;; /* Set width to 50% */
  max-width: 500px;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  z-index: 9999;  /* Increase z-index to make sure it's on top */
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2); /* Add a little shadow for better visibility */
  opacity: 1; /* Set to visible by default */

  
  /* Adding transition for opacity */
  transition: opacity 0.3s;
  opacity: 0; /* By default, the banner is invisible */
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

/* Add these styles to your CSS file */

.bannerFadeIn {
  animation: bannerFadeInAnimation 0.5s forwards; /* 0.5s fade-in duration */
}

.bannerFadeOut {
  animation: bannerFadeOutAnimation 1s forwards; /* 1s fade-out duration */
}

@keyframes bannerFadeInAnimation {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes bannerFadeOutAnimation {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}


.message-banner.success {
  background-color: #4CAF50; /* Green for success messages */
  font-size: 10x;
  font-family: 'Montserrat';
  width: auto;
}

.message-banner.error {
  background-color: #f44336; /* Red for error messages */
}

.message-banner .close-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  margin-left: 20px;
  margin-bottom: 15px;
}

/*end of banner stuff*/


.error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: rgb(200, 0, 0);
}

.error-message p {
  margin-top: none;
}

.fade-in,
.fadeOut {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.visible {
  opacity: 1;
}




/* Dropbox/FileUpload */

#dropbox {
  width: 50%; 
  min-width: 450px;
  border-radius: 10px; 
  margin: 0 auto; 
  margin-bottom: 15px;
  border: 2px dashed #ccc;
  text-align: center;
  max-width: 600px;
  cursor: pointer;

}


.db {
  font-size: 18px;
  font-weight: 200;
  color: #000;
  border: 2px dashed transparent;
  transition: all 0.3s;
}

#dropbox:hover {
  cursor: pointer;}

#dropbox.dragover {
  border-color: #000;
}

.db:hover {
  background-color: rgba(199, 199, 199, 0.3);}

#dropbox:active {
    background-color: rgba(118, 134, 255, 0.3);}
 

#dropbox p {
  padding-left: 10px;
  padding-right: 10px;
  margin: none;

}


/*Resume Optimizer*/

.optimized-section {
  width: 100%;
  margin-top: 2rem;
  box-shadow: 0 1px 5px rgba(1, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 4px;
  margin: auto;
  box-sizing: border-box;
  border: 2px solid black;
  margin-bottom: 1rem;
}

/*buttons*/

button {
  font-family: 'Open Sans', sans-serif;
  font-size: large;
  font-weight: 700;
  margin-top: 10px;
  border-radius: 7px;
  background-color: #e5e5e5;
  border: 1px solid #f5f5f5;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s;
  width: auto;
}


.copytoclipboardbutton {
  font-family: 'Open Sans', sans-serif;
  font-size: large;
  font-weight: 700;
  margin-top: 10px;
  border-radius: 7px;
  background-color: #e5e5e5;
  border: 1px solid #f5f5f5;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: all 0.3s;
  width: auto;
}


button.copytoclipboardbutton:hover {
  background-color: #bbbbbb;
  border-color: #e0e0e0;
}

button.generate:hover {
  background-color: #bbbbbb;
  border-color: #e0e0e0;
}


button.newcoverletterbutton:hover {
  background-color: #bbbbbb;
  border-color: #e0e0e0;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 0px !important;  
}

.button-group > button {
  flex: 1 1 0;
}

.button-1 {
  align-items: center;
  background-color: #f4f4f4;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin-top: 10px;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  vertical-align: baseline;
  width: 300px;
  margin: auto;
  flex: 1 0 auto;
  margin: 0.5rem;
}

.button-2 {
  align-items: center;
  background-color: #f4f4f4;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin-top: 10px;
  min-height: 3rem;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  vertical-align: baseline;
  width: 100%;
  margin: auto;
}

.button-3 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.452);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 300;
  justify-content: center;
  line-height: 1.25;
  margin-top: 10px;
  min-height: 3rem;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  vertical-align: baseline;
  width: 50%;
  max-width: 200px;
  margin: auto;
  margin: 5px;
}

.button-3:hover {
  background-color: #f4f4f4;
  border-color: #e0e0e0;
}

.button-4 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.452);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 15px;
  font-weight: 300;
  justify-content: center;
  line-height: 1.25;
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  vertical-align: baseline;
  width: 100px;
  height: 30px;
  max-width: 200px;
  float: left;
}

.button-4:hover {
  background-color: #f4f4f4;
  border-color: #e0e0e0;
}

.update-group {
  display: flex;
  align-items: center; 
  gap: 15px; 
}




/*button 1*/

.button-1:hover {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.08) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-1:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.08) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-1:hover {
  transform: translateY(-1px);
  background-color: rgba(220, 220, 220, 0.85);
}

.button-1:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

/*button 2*/

.button-2:hover {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.08) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-2:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.08) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-2:hover {
  background-color: rgba(220, 220, 220, 0.85);
}

.button-2:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

/*chat/interview CSS*/
.chat {
  max-height: 400px;
  overflow-y: auto;
  padding: 20px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.message.user {
  background-color: #007bff;
  color: white;
  text-align: right;
  border-bottom-right-radius: 0;
}

.message.bot {
  background-color: #f8f9fa;
  color: black;
  text-align: left;
  border-bottom-left-radius: 0;
}




/*instructions for components*/
.instructions {
  display: flex;
  flex: 0.3;
  flex-direction: column;
  background-color: #f7f7f7;
  color: rgb(110, 110, 110);
  margin-top: 10vh;
  box-shadow: 0 8px 20px rgba(1, 0, 0, 0.2);
  border-radius: 14px;
  overflow: none;
  padding: 1rem;
  max-width: 600px !important;
}

.instructions-fadein {
  opacity: 1;
  width: auto;
  transition: opacity 1s, visibility 1s, width 2s, margin 1s, height 1s, padding 1s;
}

.instructions-fadeout {
  opacity: 0;
  pointer-events: none; /* To ensure that the faded-out content isn't interactable */
  height: 0 !important;
  transition: opacity 0s, visibility .3s, width .3s, margin .3s, height 0s, padding .3s;
  padding: 0;
  margin: 0 !important;
  width: 0px !important;
}

.instructions-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.instructions-close {
  cursor: pointer;
  float: right;
  font-size: 25px;
  padding-right: 10px;
  padding-top: 10px;
}

.show-instructions {
  cursor: pointer;
  float: right;
  font-size: 25px;
  margin-right: -10px;

}


/* General CSS */
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: transform 0.5s ease-in-out, width 0.5s ease-in-out;
  margin-bottom: 90px;
}

.body {
  overflow-x: hidden;
}

.ComponentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding-bottom: .5em;
  padding-bottom: 30px;
  overflow: hidden;

}

/*input stuff*/

/*input form transform animation*/
.input-form {
  transform: translateY(0), translateX(0);
  transition: transform 0.5s 0.5s, width 0.5s;
  flex-grow: inherit;
}

/*input form for just resume components*/
.input-form.resume {
  height: auto;
}

.input-container.resume {


  height: auto;
}


input {
  appearance: auto;
  background-color: rgb(255, 255, 255);
  border-color: rgb(194, 185, 168);
  border-radius: 8px;
  border-style: solid;
  border-width: 0.7px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  cursor: text;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 41.3333px;
  padding: 8px 16px;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-rtl-ordering: logical;
}


.inputlabel {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 18px;
  font-style: normal;
  width: auto;
}

.input-form.section {
  margin-bottom: 20px;
}

.input-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 8px 20px rgba(1, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 2%;
  overflow: none;
}

/*group of h1, buttons and input fields*/
.input-group {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  
}

.titleandsave {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}






/* Media queries */


@media (min-width: 1200px) {
  .input-form  {
    width: 100%;
    max-width: 1200px;
  }

  .result-container {
    width: 85%;
    max-width: 1200px;
  }
}

@media (max-width: 1200px) {
  .main-container {
    flex-direction: column;
  }

  .result-container {
    width: 85%;
  }

.ComponentContainer {
    width: 90%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
  }
}




.input-container {
  transition: width 0s ease-in-out;
}

@media (min-width: 1445px) {
  .input-container {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: auto;
    max-width: 1200px;
    height: auto;
  }
  
  .input-form {
    flex: 1;
    width: auto;
    height: 100%;
    
  }
  
  .instructions {
    flex: 0.25;
    margin-left: 40px;
    width: 200px;
    height: 100%;
    max-height: 100%;

  }

  .instructions.resume {
    margin-top: 2.5em;
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }

  .input-form.resume {
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .input-container.resume {
    flex-direction: column;

    height: auto;
  }


  
}

@media (max-width: 1445px) {
  .instructions {
    margin-top: 2.5em;
    margin-left: auto;
    margin-right: auto;
  }
}




@media (min-width: 767px) {
.input-container {
width: 85%;
}
}


@media (max-width: 767px) {
.input-container {
  width: 100%;
}
.result-container {
  width: 100%;
}
}

@media (max-width: 600px) {
  .button-group {
    flex-direction: column;
    align-items: center;
  }

  .button-group > button {
    flex: 1 1 auto;
  }

  #dropbox {
    min-width: 300px;
  }

  .textarea-large {
    max-width: 90%;
  }

  .result-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}