/* Profile Page */

/* Profile Page aligns things in the center*/
.profilepage {
  width: 80%;
  min-width: 300px;
  max-width: 900px;
  align-content: center;


}

.profileinstructions {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 5px;
  background: #d3d3d314;
}

.auth0info {
  width: 50%;

}

.profileform {
width: 100%;
}

.profileinput {
  margin-top: 20px;
}

.profileform input {
  max-width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
}

.input-form-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 8px 20px rgba(1, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 2%;
  overflow: none;
}

.update-group {
  margin-top: 15px;
}

.input-group-profile {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#dropboxprofile {
  width: 100%; 
  border-radius: 10px; 
  margin-bottom: 15px;
  margin-top: 15px;
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
}

.authprofileinfo {
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}




.profile-instructions-list li {
  padding: 10px 0;  /* Adds 10px padding to the top and bottom of each list item */
}

.instructions h3 {
  margin-left: 20px;
}

@media only screen and (max-width: 600px) {
  .authprofileinfo {
      display: flex;
      flex-direction: column;
  }
  
  .auth0info {
      order: 1;
  }
  
  .profileinstructions {
      order: 2;

  }

  .profilepage {
    width: 90%;
  }

  .profileheader {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: center;
  }
}


.divider {
  border-bottom: 1px solid #ccc;
  margin: 20px 0;
}

/* Form */
form {
  margin: 20px 0;
}

form div {
  margin-top: 5px;
}

label {
  display: inline-block;
  width: 150px;
  font-weight: bold;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .profilepage {
    padding: 20px;
  }
}